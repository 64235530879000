<template>
    <div v-show="active" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 text-gray-900">
      <div class="bg-white rounded-lg px-10 py-10 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-10">
            <div class="text-center sm:mt-0 sm:text-left">
                <div class="flex flex-row justify-between">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                    SendX Plans
                    </h3>
                    <button @click.prevent="modalCancelClicked" class="p-1 hover:border-2 rounded-full">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="10" height="10" overflow="visible" stroke="black" stroke-width="10" stroke-linecap="round">
                            <line x1="0" y1="0" x2="50" y2="50" />
                            <line x1="50" y1="0" x2="0" y2="50" />
                        </svg>
                    </button>
                </div>
                <div class="mt-10 flex flex-row justify-between">
                    <div>
                        <h1 class="flex text-lg leading-5 text-gray-500">
                         <span> ${{ selectedPrice }}/mo</span>
                          <div v-if="isDiscountAvailabel"
                            class="ml-2 relative grid select-none items-center whitespace-nowrap rounded-lg bg-amber-200 p-1 font-sans text-xs uppercase text-yellow-900">
                            <span class="">{{ discount }}% off</span>
                           </div>
                        </h1>
                        <h3 v-if="!isMonthlyBillingType" class="text-sm line-through leading-5 text-gray-500">
                          ${{ selectedMonthlyPrice }}/mo 
                        </h3>
                        <span class="text-sm">Billed {{ billingTypeUnit }}</span>
                    </div>
                    <div class="flex flex-col items-center"> 
                        <button @click.stop="toggleBillingType" type="button"
                        :class="isMonthlyBillingType ? 'bg-gray-200' : 'bg-emerald-500'"
                        class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-emerald-500"
                        role="switch" aria-checked="false">
                        <span class="sr-only">billing status</span>
                        <span :class="isMonthlyBillingType ? 'translate-x-5' : 'translate-x-0'" aria-hidden="true"
                            class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transcta ring-0 transition ease-in-out duration-200"></span>
                        </button>
                        <span class="text-xs mt-2 transition ease-in-out duration-200">{{ billingTypeName }}</span>
                    </div>
                </div>

                <div class="mt-2">
                    <SettingPaymentDropdown :options="options" :value="selectedOption" @input="handleDropdownInput" />
                    <span class="mt-4 pt-4 text-sm">monthly contacts and unlimited emails.</span>
                </div>
            </div>


            <div class="mt-5 mb-2 sm:mt-4 sm:flex sm:flex-row-reverse">
                <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                    <button v-if="isBillingTypeActive" type="is-info" class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-blue-900 text-base leading-6 font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                        Your Current Plan
                    </button>
                    <button v-else @click.stop="handlePurchase" type="is-success" class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-emerald-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-emerald-500 focus:outline-none focus:border-emerald-700 focus:shadow-outline-emerald transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                        Purchase Business Plan
                    </button>
                </span>
            </div>
      </div>
    </div>
  
  </template>
  
<script setup>
import { defineProps, defineEmits } from 'vue'
import { useSettingStore } from "~/stores/setting/store.js";

const settingStore = useSettingStore();

const props = defineProps({
    active: {
        type: Boolean,
        default: () => false,
    },
    selectedPrice: {
        type: String,
        default: () => "computing...",
    },
    selectedMonthlyPrice: {
        type: String,
        default: () => "computing...",
    },
    isDiscountAvailabel: {
        type: Boolean,
        default: () => false,
    },
    discount: {
        type: Number,
        default: () => 0,
    },
    isMonthlyBillingType: {
        type: Boolean,
        default: () => true,
    },
    selectedOption: {
        type: Number,
        default: () => 0,
    },
    options: {
        type: Array,
        default: () => [],
    }
});

const emit = defineEmits(['cancelModalClicked', 'toggleBillingType', 'handleDropdownInput', 'handlePurchase']);

var BILLING_MONTHLY = 0;
var BILLING_YEARLY = 1;

const billingTypeName = computed(() => {
    let showBillingType = "Billing Monthly";
    if(!props.isMonthlyBillingType) {
        showBillingType = "Billing Yearly";
    }
    return showBillingType;
})

const billingTypeUnit = computed(() => {
    if(props.isMonthlyBillingType) {
        return "month on month";
    } else {
        return "Yearly";
    }
})

const isBillingTypeActive = computed(() => {
    if(settingStore.$state.billingDuration === BILLING_MONTHLY){
        return props.isMonthlyBillingType;
    } else if(settingStore.$state.billingDuration === BILLING_YEARLY) {
        return !props.isMonthlyBillingType;
    } 
    return false
})

const modalCancelClicked = () => {
    emit("cancelModalClicked");
};

const toggleBillingType = () => {
    emit("toggleBillingType");
};

const handleDropdownInput = (e) => {
    emit('handleDropdownInput', e);
};

const handlePurchase = () => {
    emit('handlePurchase');
};


</script>

<style scoped>
input:focus {
  outline: none !important;
}

</style>
  