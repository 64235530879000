<template>
  <svg viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.1851 29.4281C16.8443 29.4283 16.5169 29.2939 16.2734 29.0538L11.0593 23.9083L12.8825 22.0334L16.6956 25.7951L25.3545 3.73145L3.62318 12.9011L6.71635 15.9497C6.86071 16.092 6.97021 16.2661 7.03619 16.4584C7.10217 16.6506 7.12281 16.8557 7.09648 17.0574L6.56666 21.1629L14.0448 13.4769L15.9068 15.3129L5.71221 25.8019C5.52123 26.0018 5.27226 26.1356 5.00105 26.1842C4.72983 26.2328 4.4503 26.1936 4.20258 26.0723C3.95486 25.9509 3.75168 25.7537 3.6222 25.5088C3.49272 25.264 3.4436 24.9841 3.48188 24.7094L4.42885 17.3639L0.392066 13.3872C0.238202 13.2358 0.123988 13.0484 0.0596304 12.8417C-0.00472744 12.635 -0.017219 12.4155 0.023268 12.2028C0.0637551 11.99 0.155961 11.7907 0.291643 11.6226C0.427326 11.4545 0.60225 11.3229 0.800789 11.2396L27.1929 0.101881C27.4295 0.00211761 27.69 -0.0251499 27.9419 0.0234869C28.1938 0.0721236 28.4259 0.194506 28.609 0.375311C28.7922 0.556116 28.9183 0.787308 28.9716 1.03994C29.0248 1.29257 29.0029 1.55542 28.9086 1.79557L18.3961 28.6049C18.3175 28.8064 18.1904 28.9851 18.0263 29.1249C17.8622 29.2647 17.6662 29.3613 17.4559 29.406C17.3667 29.4235 17.2759 29.4308 17.1851 29.4281Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'SendXArrow'
}
</script>

<style scoped>

</style>
