<template>
  <div class="bg-white w-52 shadow-lg rounded-md border border-gray-50">
    <div class="flex flex-col py-2">
      <NuxtLink :to="$config.public.routes.Campaign.Newsletter.Home"
        class="px-4 py-2 text-sm leading-5 text-gray-500 hover:text-gray-600 hover:bg-gray-50">
        Newsletters
      </NuxtLink>
      <NuxtLink :to="$config.public.routes.Campaign.Drip.Home"
        class="px-4 py-2 text-sm leading-5 text-gray-500 hover:text-gray-600 hover:bg-gray-50">
        Drips
      </NuxtLink>
      <NuxtLink :to="$config.public.routes.Campaign.ABTest.Home"
        class="px-4 py-2 text-sm leading-5 text-gray-500 hover:text-gray-600 hover:bg-gray-50">
        A/B Split Test
      </NuxtLink>
      <NuxtLink :to="$config.public.routes.Campaign.AutoTrigger.Home"
        class="px-4 py-2 text-sm leading-5 text-gray-500 hover:text-gray-600 hover:bg-gray-50">
        Auto-Trigger
      </NuxtLink>
      <NuxtLink :to="$config.public.routes.Campaign.RSS.Home"
        class="px-4 py-2 text-sm leading-5 text-gray-500 hover:text-gray-600 hover:bg-gray-50">
        RSS
      </NuxtLink>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped></style>
