<template>
<div class="bg-white w-52 shadow-lg rounded-md border border-gray-50">
  <div class="flex flex-col py-2">
    <nuxt-link v-if="globalStore.checkVisibilitity({entity: AppEntities.Contact})" :to="$config.public.routes.Audience.Contact.Home" class="px-4 py-2 text-sm leading-5 text-gray-500 hover:text-gray-600 hover:bg-gray-50">
      Contacts
    </nuxt-link>
    <nuxt-link :to="$config.public.routes.Audience.List.Home" class="px-4 py-2 text-sm leading-5 text-gray-500 hover:text-gray-600 hover:bg-gray-50">
      Lists
    </nuxt-link>
    <nuxt-link :to="$config.public.routes.Audience.Tag.Home" class="px-4 py-2 text-sm leading-5 text-gray-500 hover:text-gray-600 hover:bg-gray-50">
      Tags
    </nuxt-link>
    <nuxt-link :to="$config.public.routes.Audience.Segment.Home" class="px-4 py-2 text-sm leading-5 text-gray-500 hover:text-gray-600 hover:bg-gray-50">
      Segments
    </nuxt-link>
  </div>
<!--  <div class="bg-gray-50 p-4">-->
<!--    <div class="flex flex-row items-center space-x-1 text-gray-600">-->
<!--      <span class="text-base leading-6">SendHealth</span>-->
<!--      <svg xmlns="http://www.w3.org/2000/svg" class="text-gray-500 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">-->
<!--        <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />-->
<!--        <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />-->
<!--      </svg>-->
<!--    </div>-->
<!--    <div class="text-sm pt-1 text-gray-400">-->
<!--      Platea leo, turpis in ut consectetur pulvinar.-->
<!--    </div>-->
<!--  </div>-->
</div>
</template>

<script setup>
import {AppEntities} from "~/constants"
const globalStore = useGlobalStore();
</script>

<style scoped>

</style>
