<template>
    <div>
        <div  v-click-outside="closeOptions" class="relative">
            <button :disabled="disabled" @click.stop="toggleShowOptions" type="button"
                class="cursor-pointer bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label">
                <span class="block truncate">
                    {{ value === "" ? '' : chosenValue(value) }}
                </span>
                <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" viewBox="0 0 20 20"
                        fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clip-rule="evenodd" />
                    </svg>
                </span>
            </button>
            <transition enter-active-class="" enter-class="" enter-to-class=""
                leave-active-class="transition ease-in duration-100" leave-class="opacity-100" leave-to-class="opacity-0">
                <div ref="optionsRef" v-if="showOptions"  @scroll="handleScroll"
                    class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-32 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                    tabindex="-1" role="listbox" aria-labelledby="listbox-label">
                    <ul class="mt-2">
                        <li v-for="(option, index) in options" :id="`listbox-option-${index}`"
                            :key="`listbox-option-${index}`"
                            :class="highlightedIndex === index ? 'text-blue-600  bg-gray-100' : 'text-gray-900'"
                            @click.stop="selectOption(option, index)" @mouseover="mouseover(index)"
                            class="cursor-pointer select-none relative py-2 pl-3 pr-9" role="option">
                            <span class="font-normal block truncate">
                                {{ option.contact }}
                            </span>
                            <span v-if="selectedOption === index"
                                :class="highlightedIndex === index ? 'text-blue-600' : ''" class="absolute inset-y-0 right-0 flex items-center pr-4">
                                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                </svg>
                            </span>
                        </li>
                    </ul>
                </div>
            </transition>
        </div>
    </div>
  </template>
<script setup>
  import { defineProps, ref } from 'vue';
  const props = defineProps({
    value: {
        type: Number,
        default: 0,
    },
    label: {
        type: String,
        default: 'label goes here',
    },
    options: {
        type: Array,
        default: () => [],
    },
    selectHighlighted: {
        type: Boolean,
        default: false,
    },
    disabled: {
      type: Boolean,
      default: false
    }
  });
  const emit = defineEmits(['input', 'scrollEnd'])
  const selectedOption = ref(props.value);
  const showOptions = ref(false);
  const highlightedIndex = ref(0);
  const filterdOptions = ref(props.options)
  const optionsRef = ref(null);
  const searchValue = ref("");
  
  const closeOptions = () => {
    showOptions.value = false;
    searchValue.value = "";
    filterdOptions.value = props.options
  };
  
  const toggleShowOptions = (e) => {
    showOptions.value = !showOptions.value;
    // dataIndex.value = 10;
  };

  const chosenValue = (index) => {
    console.log("index", index)
    return props.options[index].contact
  }

  const selectOption = (option,index, hideOptions = true) => {
    console.log(props.options[index], index, selectedOption.value);
    if(index === selectedOption.value) {
        console.log("same index", index);
        return;
    }
    selectedOption.value = index;
    emit('input', index);
    showOptions.value = !hideOptions;
  };
  
  const mouseover = (index) => {
    highlightedIndex.value = index;
  };
  
  </script>
  
  
  <style scoped></style>
  